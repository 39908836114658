import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "input-checkbox__container" }
const _hoisted_2 = { class: "input-checkbox__input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("div", {
    class: ["input-checkbox", 'input-checkbox--' + _ctx.size]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("input", {
          class: "input-checkbox__input",
          type: "checkbox",
          id: _ctx.inputId,
          name: _ctx.name,
          title: _ctx.label,
          checked: _ctx.modelValue,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, null, 40, ["id", "name", "title", "checked"]),
        _createVNode("div", {
          class: ["input-checkbox__item", {'input-checkbox__item--checked': _ctx.modelValue}]
        }, [
          (_ctx.modelValue)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                class: "input-checkbox__icon",
                name: "check"
              }))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      (_ctx.label)
        ? (_openBlock(), _createBlock("label", {
            key: 0,
            class: ["input-checkbox__label text--", {'sr-only': _ctx.labelHidden}],
            for: _ctx.inputId
          }, _toDisplayString(_ctx.label), 11, ["for"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}