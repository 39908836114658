
import { defineComponent, reactive } from 'vue';
import Table from './Table.vue';
import Button from '@/components/Button/Button.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputCheckbox from '@/components/Input/Checkbox/InputCheckbox.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import { mockProps, tableHeaders, tableItems } from './mockup';
import { sizes } from '@/models/components/size.model';

export default defineComponent({
  name: 'SampleButton',
  components: {
    Table,
    Button,
    InputText,
    InputCheckbox,
    InputSelect,
  },
  setup() {
    function action(item: any) {
      console.log('Action for: ', item);
    }
    return {
      props: reactive(mockProps),
      tableHeaders,
      tableItems,
      sizes,
      action,
    };
  },
});
