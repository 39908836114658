import { TableHeader } from './model/TableHeader';

export const mockProps = {
  title: 'Table title',
  loading: false,
  compact: false,
  filter: true,
  paginator: false,
  emptyText: 'No data',
  loadingText: 'loading data',
  size: 'md',
  sendHeaders: true,
  sendItems: true,
};

export const tableHeaders: TableHeader[] = [
  {
    id: 'name',
    title: 'Name',
    itemKey: 'name',
    //primary: true,
    wrap: true,
    sortKey: 'name',
    show: true,
    align: 'left',
  }, {
    id: 'surname1',
    title: 'First surname',
    itemKey: 'surname1',
    align: 'center',
  }, {
    id: 'surnames',
    title: 'Surnames',
    align: 'right',
    wrap: true,
    sortKey: 'surname1',
  }, {
    id: 'actions',
    compact: true,
  },
];

export const tableItems = [
  {
    id: '123',
    name: 'Albert',
    surname1: 'Griso',
    surname2: 'Mendez',
    age: 28,
  }, {
    id: '125',
    name: 'Marina',
    surname1: 'Martí',
    surname2: 'Pascal',
    age: 25,
  }, {
    id: '133',
    name: 'Júlia',
    surname1: 'Romero',
    surname2: 'De la Fuente',
    age: 18,
  },
];